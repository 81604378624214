<template>
  <div class="account-content">
    <GoBack />

    <div class="settings-title settings-title--small">Настройки Telegram-бота </div>

    <div class="school-cabinet_info-block">

      <div v-if="profileCurrent.user.telegram?.is_telegram_bot_username_confirmed == false && !profileCurrent.user.telegram?.telegram_username" class="school-cabinet_info-body">
        <div class="school-cabinet_info-text">
          <p class="school-cabinet_info-text-item">Подключите наш Telegram-бот, чтобы делиться фото и видео с вашими учениками. 
            Все медиафайлы будут доставлены им напрямую в Telegram.
          </p>
          <p class="school-cabinet_info-text-item school-cabinet_info-text-item--bold">                        
            ⚠️ Обратите внимание, что медиафайлы будут отправлены клиенту только в том случае, если он также подключён к боту.
          </p>
        </div>
        <p class="school-cabinet_info-label">Введите ваш Username в Telegram </p>
        <div class="d-flex justify-content-between flex-sm-row flex-column">                          
          <div class="school-cabinet_info-field school-cabinet_info-field--full my-sm-0">
            <input 
              type="text" 
              class="form-control form-control--bordered" 
              :class="{ 'form-control--error': $v.telegramUserName.telegram.$error || errors.includes('telegram') }"
              placeholder="Username"
              v-model.trim="telegramUserName.telegram"
              @focus="hideErrorHighlight('telegram'); $v.telegramUserName.telegram.$reset()"
            >
            <div class="text-error" v-if="!$v.telegramUserName.telegram.required && $v.telegramUserName.telegram.$dirty">Поле обязательно для заполнения</div>
          </div>
          <div class="mx-sm-3">
            <button v-if="loading.addTelegram" class="btn btn-blue btn-full h-44">
              <AppLoader2 :mini="true" :color="'#ffffff'" />
            </button>
            <button v-else @click="addTelegram" class="btn btn-blue btn-full h-44">Сохранить</button>
          </div>
        </div>
      </div>

      <div v-if="profileCurrent.user.telegram?.is_telegram_bot_username_confirmed == false && profileCurrent.user.telegram?.telegram_username" class="school-cabinet_info-body">
        <div class="d-flex justify-content-between align-items-md-center align-items-stretch flex-md-row flex-column flex-wrap mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <p class="school-cabinet_info-text-item mr-md-5 mr-3">Username </p>
            <p v-if="!isEdit" class="school-cabinet_info-text-item school-cabinet_info-text-item--bold">
              {{ profileCurrent.user.telegram?.telegram_username }} <span class="school-cabinet_info-status"></span>
            </p>
            <input 
              v-if="isEdit"
              type="text" 
              class="form-control form-control--bordered" 
              :class="{ 'form-control--error': $v.telegramUserName.telegram.$error || errors.includes('telegram') }"
              placeholder="Username"
              v-model.trim="telegramUserName.telegram"
              @focus="hideErrorHighlight('telegram'); $v.telegramUserName.telegram.$reset()"
            >
          </div>
          <div class="ml-auto">
            <button v-if="!isEdit" class="btn btn-blue-text" @click="openInput">
              Изменить
            </button>
            <template v-if="isEdit">
              <button v-if="loading.addTelegram" class="btn btn-blue btn-full h-44">
                <AppLoader2 :mini="true" :color="'#ffffff'" />
              </button>
              <button v-else @click="addTelegram" class="btn btn-blue btn-full h-44">Сохранить</button>
            </template>
          </div>
        </div>
        <div class="school-cabinet_info-text school-cabinet_info-text--bordered">
          <p class="school-cabinet_info-text-item">
            Чтобы активировать Telegram-бот, перейдите по ссылке
            <a :href="`${profileCurrent.user.telegram?.telegram_bot_link}`" target="_blank" class="school-cabinet_info-link">{{ profileCurrent.user.telegram?.telegram_bot_link }}</a>
          </p>
        </div>
      </div>

      <div v-if="profileCurrent.user.telegram?.is_telegram_bot_username_confirmed == true" class="school-cabinet_info-body">
        <div class="d-flex justify-content-between align-items-md-center align-items-stretch flex-md-row flex-column flex-wrap">
          <div class="d-flex justify-content-between">
            <p class="school-cabinet_info-text-item mr-md-5 mr-3">Username </p>
            <p class="school-cabinet_info-text-item school-cabinet_info-text-item--bold">
              {{ profileCurrent.user.telegram?.telegram_username }}  <span class="school-cabinet_info-status active"></span>
            </p>
          </div>
          <div class="ml-auto">
            <button v-if="loading.disableTelegram" class="btn btn-gray-text">
              <AppLoader2 :mini="true" :color="'#a7a7a7'" />
            </button>
            <button v-else class="btn btn-gray-text" @click="disableAccount">
              Отключить
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';

import { required } from 'vuelidate/lib/validators'

import GoBack from '@/components/GoBack.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';


import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';

Vue.use(VueToast);
Vue.use(Vuelidate);

export default {
  name: 'ProfileAddTelegram',

  components: {
    GoBack,
    AppLoader2,
  },

  data() {
    return {
      telegramUserName: {
        telegram: '',
      },
      errors: [],
      isEdit: false,
    };
  },

  computed: {
    ...mapGetters({
      profileCurrent: 'profile',
      loading: 'loadingProfile',
    })
  },

  async created() {
    await this.getProfile();
    this.telegramUserName.telegram = this.profileCurrent.user.telegram?.telegram_username;
  },

  methods: {
    ...mapActions(['getProfile', 'addTelegramBot', 'disableTelegramAccount']),
    addTelegram() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        this.addTelegramBot(this.telegramUserName)
          .then(() => {
            this.$toast.open({
              message: 'Username успешно сохранен',
              type: 'success'
            });

            this.getProfile();
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          });
      }
    },
    disableAccount() {
      this.disableTelegramAccount(this.telegramUserName)
      .then(() => {
        this.$toast.open({
          message: 'Telegram-аккаунт успешно отключен',
          type: 'success'
        });

        this.$router.push('/settings/edit');
      })
      .catch((error) => {
        this.errorHandlerForResponse(error);
      });
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        switch (el) {
          case 'telegramUserName.telegram':
            this.errors.push('telegram');
            break;
        }
      })
    },
    openInput() {
      this.isEdit = true
    },
    errorHandlerForResponse,
    hideErrorHighlight
  },
  validations: {
    telegramUserName: {
      telegram: {
        required
      },
    }
  }
}
</script>