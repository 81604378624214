<template>
  <div class="modal">
    <div class="modal__bg active"></div>
    <div class="modal__wrapper active" ref="modal" @click="handleClickOutside">
      <div class="modal__window modal__window--small" ref="modalBody">
        <p class="modal__text">
          Чтобы отправить медиа клиенту, пожалуйста, подключитесь к Telegram-боту  
          в разделе “Настройки профиля”
        </p>
        <div class="modal__button">
          <router-link to="/settings/edit/add-telegram" class="btn-green">Подключиться</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'modalTelegramBot',
    methods: {
      handleClickOutside(event) {
        const { modalBody } = this.$refs;
        if (!modalBody.contains(event.target)) {
          this.$emit('close');
          this.$el.classList.remove('active');
        }
      },
    },
  };
</script>

<style scoped>
.btn-green {
  display: inline-flex;
  align-items: center;
}
</style>