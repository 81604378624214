<template>
  <div class="account-content">
    <GoBack />

    <div class="d-flex justify-content-between align-items-center  flex-wrap">
      <div class="account-subtitle pt-0  mb-3 mr-5">Медиа клиента {{client?.name?.full}}</div>
      <div v-if="client?.telegram?.is_telegram_bot_username_confirmed" class="d-flex align-items-center justify-content-between mb-3 ml-auto">
        <div class="mr-3">
          <button v-if="profileCurrent.user.telegram?.is_telegram_bot_username_confirmed == false" class="btn-white js-open-modal-info" @click="openModal">
            <i class="fab fa-telegram links-item-tg"></i>
            Отправить медиа клиенту
          </button>
          <button v-else class="btn-white js-open-modal-info" @click="redirectToTelegram">
            <i class="fab fa-telegram links-item-tg"></i>
            Отправить медиа клиенту
          </button>
        </div>
        <div class="account-info">
          <div class="account-info__icon" content="Медиафайлы можно отправлять клиенту, если он, как и вы, подключён к нашему Telegram-боту. Файлы доставляются в Telegram клиента и автоматически сохраняются в этом разделе. <br><br>Если вы ещё не подключились к Telegram-боту, сделайте это в разделе «Настройки профиля»." v-tippy="{ placement : 'bottom',  arrow: false }"></div>
        </div>
      </div>
    </div>

    <AppLoader v-if="loadingGallery.gallery" />

    <template v-else>
      <div v-if="isGalleryEmpty" class="photos">
        <p style="color: #999;">Здесь будут отображаться медиафайлы, отправленные клиентом через Telegram-бот</p>
      </div>
      <div v-else class="photos">
        <div v-for="(photos, date) in galleryListByDate" :key="date" class="photos__block">
          <p class="photos__date">{{ moment(date).format('DD.MM.YYYY') }}</p>
          <div class="photos__list">
            <a 
              data-fancybox="gallery"
              v-for="(photo, index) in photos"
              :key="`${photo.media_item_id}_${index}`"
              :href="photo?.url" 
              class="photos__list-item"
            >
              <img 
                v-if="photo?.type.includes('image')"
                :src="photo?.url"
                alt="" 
                class="photos__list-img"
              >
              <div v-if="photo?.type.includes('video')" class="photos__list-video">
                <video playsinline preload="metadata">
                  <source :src="photo?.url" type="video/mp4" />
                </video>
              </div>
            </a>
          </div>
        </div>
        <infinite-loading
          @infinite="infiniteHandler"
          class="mt-4"
        >
          <div slot="spinner">
            <AppLoader2 :mini="true" />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </template>

    <Modal v-if="isOpen" @close="isOpen = false" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import GoBack from '@/components/GoBack.vue';
import Modal from '@/components/modalTelegramBot.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import InfiniteLoading from 'vue-infinite-loading';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import VueTippy, { TippyComponent } from 'vue-tippy';

Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

export default {
  name: 'ClientGallery',

  components: {
    GoBack,
    Modal,
    AppLoader,
    AppLoader2,
    InfiniteLoading,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    ...mapGetters({
      gallery: 'gallery',
      galleryList: 'galleryList',
      galleryListByDate: 'galleryListByDate',
      client: 'client',
      loadingGallery: 'loadingGallery',
      meta: 'galleryMeta',
      profileCurrent: 'profile',
    }),

    totalPhotos() {
      return this.galleryList.length;
    },

    isGalleryEmpty() {
      return !this.galleryListByDate || Object.keys(this.galleryListByDate).length === 0;
    }
  },

  async created() {
    let payload_gallery = {
      id: this.$route.params.id,
      laoading: true,
    };

    await this.getGallery(payload_gallery);

    await this.setGalleryViewed(payload_gallery.id);

    await this.getClient(payload_gallery.id);

    await this.getProfile();
  },

  destroyed() {
    this.clearGallery();
    this.clearClient();
  },

  methods: {
    ...mapActions([
      'getGallery',
      'getClient',
      'clearGallery',
      'clearClient',
      'setGalleryViewed',
      'getProfile',
      'setConnectWithTelegram',
    ]),
    infiniteHandler($state) {
      const totalPhotos = this.totalPhotos;

      if (totalPhotos < this.meta.total) {
        const payload_gallery = {
          id: this.$route.params.id,
          laoading: true,
        };

        this.getGallery(payload_gallery)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    openModal() {
      this.isOpen = true;
    },
    redirectToTelegram() {
      this.setConnectWithTelegram(this.$route.params.id)
      .then(() => {
        this.$toast.open({
          message: 'Проверьте ваш Telegram: теперь вы можете отправить медиа клиенту через бота LevelApp.pro',
          type: 'success',
          duration: 10000
        });
      })
      .catch((error) => {
        this.errorHandlerForResponse(error);
      });
    },
    moment,
  },

  mounted() {
    Fancybox.bind('[data-fancybox="gallery"]', {
      video: {
        controls: true,
      }
    });
  },
}
</script>

<style>
.photos__list-video {
  width: 100%;
  height: 100%;
  position: relative;
}
.photos__list-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
}
.photos__list-video:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  position: absolute;
}
.photos__list-video:after {
  content: '';
  width: 29px;
  height: 29px;
  background-image: url(~@/assets/img/play.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>