import instance from '../instance';
import settings from '../settings';

import setPermission from '@/helpers/setPermission';

export default {
  state: {
    profile: null,
    loading: {
      profile: true,
      profileForLogin: false,
      editProfile: false,
      changePassword: false,
      addTelegram: false,
      disableTelegram: false,
      changeAvatar: false
    }
  },

  getters: {
    profile: state => state.profile,
    loadingProfile: state => state.loading,
  },

  actions: {
    async getProfile({ commit }, loading = true) {
      if (loading) {
        commit('START_PROFILE_LOADING');
      }

      if (loading === 'profileForLogin') {
        commit('START_PROFILE_FOR_LOGIN_LOADING');
      }

      try {
        const profile = await instance.get(`${settings.API_URL}/profile`);

        commit('SET_PROFILE', profile.data);
      } finally {
        commit('FINISH_PROFILE_LOADING');

        if (loading === 'profileForLogin') {
          commit('FINISH_PROFILE_FOR_LOGIN_LOADING');
        }
      }
    },
    clearProfile({ commit }) {
      commit('CLEAR_PROFILE');
    },
    async editProfile({ commit }, profile) {
      commit('START_EDIT_PROFILE_LOADING');

      try {
        await instance.patch(`${settings.API_URL}/profile`, profile);
      } finally {
        commit('FINISH_EDIT_PROFILE_LOADING');
      }
    },
    setProfileAfterEdit({ commit }, profile) {
      commit('SET_PROFILE_AFTER_EDIT', profile);
    },
    async changePasswordProfile({ commit }, password) {
      commit('START_CHANGE_PASSWORD_PROFILE_LOADING');

      try {
        await instance.post(`${settings.API_URL}/profile/password`, password);
      } finally {
        commit('FINISH_CHANGE_PASSWORD_PROFILE_LOADING');
      }
    },
    async addTelegramBot({ commit }, telegramData) {
      commit('START_ADDING_TELEGRAM_LOADING');

      try {
        await instance.post(`${settings.API_URL}/profile/telegram/connect`, telegramData);
      } finally {
        commit('FINISH_ADDING_TELEGRAM_LOADING');
      }
    },
    async disableTelegramAccount({ commit }) {
      commit('START_DISABLE_TELEGRAM_LOADING');

      try {
        await instance.post(`${settings.API_URL}/profile/telegram/disconnect`);
      } finally {
        commit('FINISH_DISABLE_TELEGRAM_LOADING');
      }
    },
    async changeAvatarProfile({ commit }, avatar) {
      commit('START_CHANGE_AVATAR_PROFILE_LOADING');

      try {
        await instance.post(`${settings.API_URL}/profile/avatar`, avatar);
      } finally {
        commit('FINISH_CHANGE_AVATAR_PROFILE_LOADING');
      }
    },
  },

  mutations: {
    SET_PROFILE(state, profile) {
      state.profile = setPermission(profile);
    },
    SET_PROFILE_AFTER_EDIT(state, profile) {
      state.profile.user = setPermission(profile).user;
    },
    START_PROFILE_LOADING(state) {
      state.loading.profile = true;
    },
    FINISH_PROFILE_LOADING(state) {
      state.loading.profile = false;
    },
    START_PROFILE_FOR_LOGIN_LOADING(state) {
      state.loading.profileForLogin = true;
    },
    FINISH_PROFILE_FOR_LOGIN_LOADING(state) {
      state.loading.profileForLogin = false;
    },
    CLEAR_PROFILE(state) {
      state.profile = null;
    },

    START_EDIT_PROFILE_LOADING(state) {
      state.loading.editProfile = true;
    },
    FINISH_EDIT_PROFILE_LOADING(state) {
      state.loading.editProfile = false;
    },

    START_CHANGE_PASSWORD_PROFILE_LOADING(state) {
      state.loading.changePassword = true;
    },
    FINISH_CHANGE_PASSWORD_PROFILE_LOADING(state) {
      state.loading.changePassword = false;
    },

    START_ADDING_TELEGRAM_LOADING(state) {
      state.loading.addTelegram = true;
    },
    FINISH_ADDING_TELEGRAM_LOADING(state) {
      state.loading.addTelegram = false;
    },
    START_DISABLE_TELEGRAM_LOADING(state) {
      state.loading.disableTelegram = true;
    },
    FINISH_DISABLE_TELEGRAM_LOADING(state) {
      state.loading.disableTelegram = false;
    },

    START_CHANGE_AVATAR_PROFILE_LOADING(state) {
      state.loading.changeAvatar = true;
    },
    FINISH_CHANGE_AVATAR_PROFILE_LOADING(state) {
      state.loading.changeAvatar = false;
    },
  },
};